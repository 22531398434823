:root {
    --white: #fff;
    --orange: #FAA986;
    --lightorange: #FAEFE8;
    --darkblue: #25335D;

 }
 .sign-in {
    background: var(--lightorange);
 }
 .banner {
    background: var(--lightorange);
 }

 .banner-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
 }
 .banner-text {
    justify-self: start;
    font-size: 200%;
    z-index: 2;
    display: flex;
    align-items: center;
    font-family: 'Roboto Flex',sans-serif;
    
    padding: 1.5vw;
 }
  
 .banner-text::before {
    content: "";
    justify-self: start;
    height: 55px;
    width: 55px;
    background-color: var(--orange);
    border-radius: 50%;
    display: inline-block;
    z-index: 0;
    position: absolute;
 }
  
 strong {
    z-index: 2;
    margin-left: 1.75vw;
 }
 .body-header {
   font-size: 400%;
   font-family: 'Roboto Flex',sans-serif;
   font-weight: 500;
   color: var(--darkblue);
}
.body-little-header{
   font-size: 225%;
   font-family: 'Roboto Flex',sans-serif;
   font-weight: 500;
   max-width: 45vw;
   margin: 1.75vw auto;
   color: var(--darkblue);
}
.body{
   background: var(--lightorange);
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   float: left; 
   width: 100%;
   height: 100%;
   position: fixed;
   top: 0;
   left: 0;
   background-repeat: no-repeat;
   background-image: url(../media/Frame\ 15.svg);
   text-align: center;
   padding: 18vw;
}
.body-buttons{
   display: flex;
   /* justify-content: space-between; */
   justify-content: space-evenly;
   align-items: center;

   width: 100%;
   
}
.banner-login {
   display: flex;
   justify-content: space-evenly;

   border: none;
   background-color: white;
   border-radius: 12px;
   font-size: 200%;
   font-family: 'Roboto Flex',sans-serif;
   cursor: pointer;
   font-weight: 500;
   color: var(--darkblue);
   padding: 12px 20px;

   width: 40%;
 }
 .banner-signup {
   display: flex;
   justify-content: space-evenly;

   /* margin-right: 20px; */
   border: none;
   /* display: inline-block; */
   font-size: 200%;
   font-family: 'Roboto Flex',sans-serif;
   cursor: pointer;
   color: white;
   background-color: #4168F6;
   padding: 12px 20px;
   border-radius: 12px;
   font-weight: 500;

   width: 40%;
 }