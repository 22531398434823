.user-card-wrapper {
    animation-name: slide;
    animation-timing-function: ease;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    position: relative;
    left: -70vw;
}

@keyframes slide {
    0% {
        left: -70vw;
    }

    100% {
        left: 0vw;
    }

}

.user-card-wrapper a {
    text-decoration: none;
}

.user-card {
    border: solid;
    border-radius: 24px;
    /* 24*/
    border-width: 0px;
    background-color: white;
    margin-bottom: 15px;
    transform: scale(1);
    transition: transform 0.15s;
    color: hsl(225, 43%, 25%);
    /* column-count: 2; */
}

.user-card:hover {
    transform: scale(1.04);
}

.card-content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 600px;
    height: 200px;
    
}

.card-heading {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 15px;
}

.user-pfp {
    width: 20px;
    height: 20px;
    margin-right: 1vw;
    border-radius: 50%;
}

.user-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;  
    gap: 7px;
    position: relative;
    top: -8px;
    
}

.user-title {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    text-align: center;
    height: 6vh;
    margin-top: -8px;
    
}

.user-title h1 {
    margin-right: 0.5vw;
    
}

.user-pronouns {
    color: rgba(37, 51, 93, 0.64);
}

.tag-content{
    margin: -2px 8px -2px 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    
}

.user-tags {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.user-tag {
    background-color: #F3F4F8;
    border-radius: 15px;
    color:  #25335D;
    font-size: 14px;
}

.tag-icon {
    font-size: 17px;
    
}

.user-tag p {
    margin: 8px;
}

.card-body {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: relative;
    top: -20px;
}

.attr-col {
    width: 220px;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.attr-icon {
    font-size: 30px;
    margin-right: 10px;
    width: 25px;
    height: 25px;
    color:  #25335D;
}

.attr-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.attr-value {
    font-size: 18px;
    font-weight: 500;
    margin: 0px;
}

.attr-desc {
    font-size: 14px;
    margin: 0px;
    color: rgba(37, 51, 93, 0.64);
}

.user-attr {
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px;
    /* background-color: rgba(255,0,0,0.5); */
}
/*
.roommate-pfps {
    display: flex;
    flex-direction: row;
    width: 96px;
    justify-content: flex-end;
    position: absolute;
    top: 15px;
    right: 15px;
}

.roommate-pfp {
    height: 30px;
    width: 30px;
    border-radius: 50%;
}
*/